<template>
  <div class="view-reset-password py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <router-link :to="{ name: 'login' }" class="d-block text-center mb-5">
            <img src="@/assets/images/logo.png" />
          </router-link>

          <form class="card" @submit.prevent="submit">
            <div class="card-body" v-if="!success">
              <h4 class="mb-4">Criar nova senha</h4>

              <form-group
                v-model="form.password"
                id="password"
                type="password"
                placeholder="Digite a nova senha"
                :errors="errors.password"
              />

              <form-group
                v-model="form.password_confirm"
                id="password_confirm"
                type="password"
                placeholder="Digite a senha novamente"
                :errors="errors.password_confirm"
              />

              <div class="text-center py-3">
                <button
                  class="btn btn-primary btn-custom-lg"
                  type="submit"
                  :disabled="loading"
                >
                  <loading :show="loading">Salvar</loading>
                </button>
              </div>
            </div>

            <div class="card-body py-5" v-else>
              <h5 class="text-center text-success my-4">
                Senha alterada com sucesso
              </h5>
              <div class="text-center">
                <router-link
                  class="btn btn-primary btn-custom-lg"
                  :to="{ name: 'login' }"
                >
                  Fazer login
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formFields } from "@/functions";

export default {
  data() {
    return {
      ...formFields(["password", "password_confirm"]),
      success: false,
    };
  },
  methods: {
    submit() {
      if (this.form.password !== this.form.password_confirm) {
        this.errors.password_confirm = ["Senha não confere"];
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("accounts/resetPassword", {
          password: this.form.password,
          token: this.$route.query.token,
        })
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.errors = error.response.data;
          let message;

          if (this.errors.non_field_errors) {
            message = this.errors.non_field_errors[0];
          } else if (this.errors.token) {
            message = "Token inválido";
          }

          if (message) {
            this.$message({
              showClose: true,
              message,
              type: "error",
              duration: 10000,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.view-reset-password {
  width: 100%;

  h1 {
    img {
      max-height: 68px;
    }
  }
}
</style>
